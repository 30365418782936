import { Select } from "antd"
import styles from "@css/modules/HomePage.module.scss"
import { ArrowDown, Ellipse } from "@css/icons/SVG"
import React, { useState } from "react"
import { addCurrency } from "../../../../utils"
import { NumberAnimation } from "../../../Animation/Number"
import { Media } from "../../../Media"
import { mobileSwitch, tabletSwitch } from "../../utils"
import { Stars } from "../../../Animation/Stars"

import Image from "next/image"
import disneyImage from "@public/images/homePage/block2/disney.png"
import { SlideAppear } from "../../../Animation/SlideAppear"

const Option = Select.Option

export function ResaleVsDirect({ resaleVsDirect }) {
    const [selected, setSelected] = useState(0)

    const onChange = (value) => setSelected(value)
    const current = resaleVsDirect[selected]
    const img = current.resortImage

    return (
        <section className={styles.resaleVsDirect}>
            <Media notMatchChildren={<Stars className={styles.stars} intersectionClass={styles.starsIntersection} starsCount={15} />} query={`(max-width: ${tabletSwitch})`} />
            <SlideAppear className={styles.resaleVsDirectLeft}>
                <label htmlFor="select-resort" className={styles.hiddenLabel}>
                    Select Resort
                </label>
                <Select id="select-resort" onChange={onChange} defaultValue={selected} className={styles.select} suffixIcon={<ArrowDown />} aria-roledescription="select resort">
                    {resaleVsDirect.map((i, key) => (
                        <Option key={key} value={key}>
                            {i.resort.short_name}
                        </Option>
                    ))}
                </Select>
                <div className={styles.resaleVsDirectResort}>
                    <Image src={img.url} alt={img.alternativeText} width={img.width} height={img.height} quality={95} />
                    <Media notMatchChildren={<div className={styles.resaleVsDirectResortName}>{current.resort.short_name}</div>} query={`(max-width: ${mobileSwitch})`} />
                    <div className={styles.resaleVsDirectResortBtn}>
                        <Image src={disneyImage} alt="disney" /> {addCurrency(current.disney)}
                        <div className={styles.baseOn}>*based on a 100 point contract</div>
                    </div>
                    <div className={styles.resaleVsDirectResortBtn}>
                        <span>Avg Resale </span>
                        {addCurrency(current.resaleAvg)}
                    </div>
                </div>
            </SlideAppear>
            <SlideAppear className={styles.resaleVsDirectRight}>
                <Stars className={styles.stars} intersectionClass={styles.starsIntersection} starsCount={15} />
                <div className={styles.overCircle}>
                    <Ellipse value={current.percentage} className={styles.circleRoot} circleClass={styles.circle} />
                    {/*<div className={styles.circleLight} />*/}
                    <div className={styles.resaleVsDirectRightText}>
                        <NumberAnimation value={current.percentage} />% <div>less than Disney&apos;s pricing</div>
                    </div>
                </div>
                <div className={styles.subCircleText}>
                    Your Savings
                    <div>
                        <NumberAnimation value={current.disney - current.resaleAvg} currency ease="power3.out" dur={3} />
                    </div>
                </div>
            </SlideAppear>
        </section>
    )
}
