"use client"
import styles from "@css/modules/HomePage.module.scss"
import Link from "next/link"
import { gsap } from "gsap/dist/gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { resortListingsCount } from "@lib/api/homePage"
import { ArrowDown } from "@css/icons/SVG"
import { useGeneralData } from "../../GeneralData/GeneralData"
import { filtersState } from "@utils/FiltersState"
import useSWR from "swr"
import { Block1DynamicContent } from "@components/HomePage/Block1/DynamicContent"

gsap.registerPlugin(ScrollTrigger)

const targets = [
    /*    {
        trigger: "." + styles.block1Layer5,
        animation: {
            y: -300
        },
        start: "top top",
        scrub: true
    },*/
    {
        trigger: "." + styles.block1Container,
        animationTarget: "." + styles.block1Layer4,
        animation: {
            z: -60,
            y: 110,
            x: 80
        },
        start: "center center",
        scrub: true
    },
    {
        trigger: "." + styles.block1Container,
        animationTarget: "." + styles.block1Layer3,
        animation: {
            z: -60,
            y: 110
        },
        start: "center center",
        scrub: true
    }
]

function useResortListingsCount(resortId: number, fallbackData: any) {
    const { data } = useSWR<number>([resortId, "resortListingsTotals"], {
        fetcher: resortListingsCount,
        keepPreviousData: true
    })
    return data || fallbackData
}

export function Block1({ totalListings }) {
    const { resorts } = useGeneralData()
    const [resort, setResort] = useState<(typeof resorts)[0]>()
    const total = useResortListingsCount(resort?.id, totalListings)
    const onChange = useCallback(
        ({ target: { value } }) => {
            setResort(resorts.find((i) => i.id.toString() === value.toString()))
        },
        [resorts]
    )
    useEffect(() => {
        let newTriggers: (GSAPTween | ScrollTrigger)[]
        ScrollTrigger.matchMedia({
            "(min-width: 769px)": function () {
                newTriggers = targets.map((i) => {
                    const { trigger, animationTarget, animation, ...rest } = i
                    return gsap.to(animationTarget || trigger, {
                        ...animation,
                        scrollTrigger: {
                            ...rest,
                            trigger
                        }
                    })
                })
                newTriggers.push(
                    ScrollTrigger.create({
                        trigger: "." + styles.block1Layer6,
                        animation: gsap.fromTo(
                            "." + styles.block1Layer6,
                            {
                                background: " linear-gradient(166.12deg, #352473 25.52%, #F1ABDE 107.41%)"
                            },
                            {
                                background: " linear-gradient(140deg, #352473 35%, #F1ABDE 84.83%)"
                            }
                        ),
                        start: "top top",
                        scrub: true
                    })
                )
                return () => {
                    newTriggers.forEach((i) => i.kill())
                    //gsap.set("." + styles.block1Layer5, { clearProps: true })
                }
            }
        })

        return () => {
            newTriggers?.forEach((i) => i.kill())
        }
    }, [])
    const url = useMemo(() => {
        if (resort) {
            return (
                "/listings?" +
                new URLSearchParams({
                    resorts: resort.short_name
                }).toString()
            )
        }
        return "/listings"
    }, [resort])
    const onClickLink = () => {
        filtersState.clear()
    }
    return (
        <div className={styles.block1Container}>
            <Block1DynamicContent />
            <div className={styles.block1TextContent}>
                <h1>Your Gateway to the Entire DVC Resale Market</h1>
                <p>Skip the Multi-Site Hassle - Browse listings from every major Disney Vacation Club resale broker.</p>
                <div className={styles.buttonsRow}>
                    <div className={styles.selectResortTotalsWrap}>
                        <select name="resort-select" className={styles.selectResortTotals} onChange={onChange}>
                            <option value={0}>Any Resort</option>
                            {resorts?.map((i) => (
                                <option key={i.id} value={i.id}>
                                    {i.short_name}
                                </option>
                            ))}
                        </select>
                        <ArrowDown />
                    </div>
                    <Link onClick={onClickLink} href={url} className={styles.viewListings}>
                        Browse {total} listings
                    </Link>
                </div>
            </div>
        </div>
    )
}
