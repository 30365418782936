import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel"] */ "/workspace/src/components/Animation/Carousel.js");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/Animation/SlideAppear.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Block1"] */ "/workspace/src/components/HomePage/Block1/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Block2"] */ "/workspace/src/components/HomePage/Block2/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Block3"] */ "/workspace/src/components/HomePage/Block3/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Blog"] */ "/workspace/src/components/HomePage/Blog/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Subscribe"] */ "/workspace/src/components/HomePage/Subscribe/index.js");
;
import(/* webpackMode: "eager" */ "/workspace/src/css/modules/HomePage.module.scss");
