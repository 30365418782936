"use client"
import { useEffect, useRef, useState } from "react"

export function calculateZoom(width = 1440, height = 858) {
    const yZoom = window.innerHeight / height
    const xZoom = window.innerWidth / width

    return Math.max(yZoom, xZoom)
}

export function useZoom(width = 1440, height = 858) {
    const zoomRef = useRef(1)
    const [_, setUpdate] = useState(0)
    if (process.browser) {
        zoomRef.current = calculateZoom(width, height)
    }
    useEffect(() => {
        const listener = () => {
            zoomRef.current = calculateZoom(width, height)
            setUpdate(zoomRef.current)
        }
        window.addEventListener("resize", listener)
        listener()
        return () => window.removeEventListener("resize", listener)
    }, [width, height])
    return zoomRef.current
}

export const mobileSwitch = "768px"
export const tabletSwitch = "991px"
