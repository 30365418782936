import React from "react"

type Props = {
    onClick?: () => void
    className?: string
    setRef?: any
}
export const LongArrowDown = ({ onClick, className, setRef }: Props) => {
    return (
        <svg ref={setRef} onClick={onClick} className={className} width="8" height="32" viewBox="0 0 8 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.64645 31.3536C3.84171 31.5488 4.15829 31.5488 4.35355 31.3536L7.53553 28.1716C7.73079 27.9763 7.73079 27.6597 7.53553 27.4645C7.34027 27.2692 7.02369 27.2692 6.82843 27.4645L4 30.2929L1.17157 27.4645C0.97631 27.2692 0.659727 27.2692 0.464465 27.4645C0.269203 27.6597 0.269203 27.9763 0.464465 28.1716L3.64645 31.3536ZM3.5 -2.18557e-08L3.5 31L4.5 31L4.5 2.18557e-08L3.5 -2.18557e-08Z"
                fill="white"
            />
        </svg>
    )
}
