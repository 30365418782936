"use client"
import styles from "@css/modules/HomePage.module.scss"
import blogCss from "@css/modules/Blogs.module.scss"
import { BlogItem } from "src/app/blog/components/BlogItem"
import Link from "next/link"
import cn from "classnames"
import { SlideAppear } from "../../Animation/SlideAppear"
import { useMediaQuery } from "../../../utils/hooks/useMediaQuery"

export function Blog({ items }) {
    const mobile = useMediaQuery("(max-width: 991px)")

    return (
        <SlideAppear className={cn(styles.blogContainer, mobile && blogCss.mobile)}>
            <h2 className={styles.titleLevel2}>Featured Blogs</h2>

            <div className={blogCss.overBlogItems}>
                {items.map((i, index) => {
                    return <BlogItem {...i} key={i.slug} mobile={mobile} index={index} />
                })}
            </div>
            <div className={styles.alCenter}>
                <Link href="/blog" className={styles.btnTransparent}>
                    View All
                </Link>
            </div>
        </SlideAppear>
    )
}
