import { uncheckedStatuses } from "@utils/const"
import { fetchRest } from "@lib/fetchRest"

async function listingCount(resortId?: string) {
    return fetchRest("listings", {
        fields: ["id"],
        filters: {
            status: { $notIn: uncheckedStatuses },
            ...(resortId ? { resort: { id: { $eq: resortId } } } : {})
        },
        pagination: {
            limit: 1,
            withCount: true
        }
    })
}
export async function getHomePageData() {
    const listingsCount = listingCount()
    const homeReq = fetchRest("home-page", {
        populate: {
            featureResorts: {
                fields: ["title", "description"],
                populate: {
                    resort: {
                        fields: ["id", "slug"]
                    },
                    image: true
                }
            },
            testimonials: {
                fields: ["title", "subTitle", "description"],
                populate: {
                    image: true
                }
            },
            partners: {
                image: true
            },
            resaleVsDirect: {
                populate: {
                    resort: {
                        fields: ["short_name"]
                    },
                    resortImage: true
                },
                fields: ["resaleAvg", "disney", "percentage"]
            },
            blogs: {
                fields: ["id", "title", "short_description", "slug", "publishedAt"],
                populate: ["preview_img"],
                pagination: {
                    limit: 3
                }
            }
        }
    })
    const [
        { data: homePage },
        {
            meta: {
                pagination: { total: listingsCountData }
            }
        }
    ] = await Promise.all([homeReq, listingsCount])
    return {
        homePage,
        listingTotal: listingsCountData || 0
    }
}

export const resortListingsCount = async ([resortId]: string[]) => {
    const { meta } = await listingCount(resortId)
    return meta?.pagination?.total || 0
}
