import { useMediaQuery } from "@utils/hooks/useMediaQuery"

export function Media({ query, defValue = false, children = null, notMatchChildren = null, render = undefined, brakeHydration = false }) {
    const match = useMediaQuery(query, defValue, brakeHydration)

    if (render) {
        return render(match)
    }
    if (match) {
        return children
    }
    return notMatchChildren
}
