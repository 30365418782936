"use client"
import styles from "@css/modules/HomePage.module.scss"
import { Books, Books2, HandWithStars } from "@css/icons/SVG"
import { ResaleVsDirect } from "./components/ResaleVsDirect"
import React, { useEffect } from "react"
import { Media } from "../../Media"
import { mobileSwitch, tabletSwitch, useZoom } from "../utils"
import { Stars } from "../../Animation/Stars"
import { gsap } from "gsap/dist/gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger"

import Image from "next/image"
import block2M1 from "@public/images/homePage/block2/mobile1.png"
import block2M2 from "@public/images/homePage/block2/mobile2.png"
import block2M3 from "@public/images/homePage/block2/mobile3.png"
import block2M4 from "@public/images/homePage/block2/mobile4.png"
import block2M5 from "@public/images/homePage/block2/mobile5.png"
import block2M6 from "@public/images/homePage/block2/mobile6.png"
import block2MHome from "@public/images/homePage/block2/mobile-home.png"

import block2i1 from "@public/images/homePage/block2/1.png"
import block2i2 from "@public/images/homePage/block2/2.png"
import block2i3 from "@public/images/homePage/block2/3.png"
import block2i4 from "@public/images/homePage/block2/4.png"
import block2i5 from "@public/images/homePage/block2/5.png"
import block2i6 from "@public/images/homePage/block2/6.png"
import block2i7 from "@public/images/homePage/block2/7.png"
import { SlideAppear } from "@components/Animation/SlideAppear"

gsap.registerPlugin(ScrollTrigger)

const ZoomContent = () => {
    const zoom = useZoom(375, 812)
    return (
        <div style={{ zoom }}>
            <Image src={block2M1} alt="block 2 layer 1" className={styles.block2Layer1mobile} />
            <Image src={block2M2} alt="block 2 layer 2" className={styles.block2Layer2mobile} />
            <Image src={block2M3} alt="block 2 layer 3" className={styles.block2Layer3mobile} />
            <Image src={block2M4} alt="block 2 layer 4" className={styles.block2Layer4mobile} />
            <Image src={block2M5} alt="block 2 layer 5" className={styles.block2Layer5mobile} />
            <Image src={block2M6} alt="block 2 layer 6" className={styles.block2Layer6mobile} />
        </div>
    )
}

export function Block2({ resaleVsDirect }) {
    useEffect(() => {
        const newTriggers = []
        ScrollTrigger.matchMedia({
            "(min-width: 769px)": function () {
                setTimeout(() => {
                    const tl = gsap.timeline({
                        scrollTrigger: {
                            trigger: "." + styles.block2container,
                            start: "top bottom",
                            end: "+=1000",
                            scrub: true
                        }
                    })
                    tl.to(
                        "." + styles.block2Layer1,
                        {
                            x: "40%",
                            y: -200
                        },
                        0
                    )
                    tl.to(
                        "." + styles.block2Layer2,
                        {
                            x: "-40%",
                            y: -200
                        },
                        0
                    )
                    tl.to(
                        "." + styles.block2Layer1,
                        {
                            x: "14%"
                        },
                        1
                    )
                    tl.to(
                        "." + styles.block2Layer2,
                        {
                            x: "-17%"
                        },
                        1
                    )
                    newTriggers.push(tl)
                    newTriggers.push(
                        gsap.to("." + styles.block2Layer3, {
                            x: -600,
                            scale: 1.5,
                            scrollTrigger: {
                                trigger: "." + styles.block2Layer3,
                                start: "-200 center",
                                end: "+=500",
                                scrub: true
                            },
                            y: -150
                        })
                    )
                    newTriggers.push(
                        gsap.from("." + styles.block2Layer7, {
                            x: 150,
                            scrollTrigger: {
                                trigger: "." + styles.block2Layer7,
                                start: "top bottom",
                                scrub: true
                            }
                        })
                    )
                })
            }
        })
        return () => newTriggers?.forEach((i) => i.kill(true))
    }, [])
    return (
        <div className={styles.block2container}>
            <Media
                render={(mobile) => {
                    const textContent = (
                        <div className={styles.block2TextContent}>
                            <h2 className={styles.titleLevel2}>What we do ?</h2>
                            <div className={styles.block2TextContentGroup}>
                                <SlideAppear className={styles.block2TextBlock} delay={1}>
                                    {!mobile && <Stars className={styles.stars} intersectionClass={styles.starsIntersectionSmallLeft} starsCount={4} />}
                                    <Books />
                                    Learn about the DVC program and pros and cons of purchasing resale
                                </SlideAppear>
                                <SlideAppear className={styles.block2TextBlock} delay={2}>
                                    <HandWithStars />
                                    Choose the resort and vacation point allotment that best fits your family
                                </SlideAppear>
                                <SlideAppear className={styles.block2TextBlock} delay={3}>
                                    <Books2 />
                                    Search the largest inventory of resale contacts on the internet
                                </SlideAppear>
                            </div>
                            <Media children={<Stars className={styles.stars} intersectionClass={styles.starsIntersection} />} query={`(max-width: ${tabletSwitch})`} />
                        </div>
                    )
                    if (mobile) {
                        return (
                            <>
                                <ZoomContent />
                                {textContent}
                                <Image src={block2MHome} alt="Mobile home layer" className={styles.block2MobileLayer1} />
                            </>
                        )
                    } else {
                        return (
                            <div className={styles.block2LayerWrapper}>
                                <Image src={block2i1} alt="block 2 layer 1" className={styles.block2Layer1} />
                                <Image src={block2i2} alt="block 2 layer 2" className={styles.block2Layer2} />
                                <Image src={block2i3} alt="block 2 layer 3" className={styles.block2Layer3} />
                                <Image src={block2i4} alt="block 2 layer 4" className={styles.block2Layer4} />
                                <Image src={block2i5} alt="block 2 layer 5" className={styles.block2Layer5} />
                                <Image src={block2i6} alt="block 2 layer 6" className={styles.block2Layer6} />
                                <Image src={block2i7} alt="block 2 layer 7" className={styles.block2Layer7} />
                                {textContent}
                            </div>
                        )
                    }
                }}
                query={`(max-width: ${mobileSwitch})`}
            />

            <SlideAppear className={styles.block2TextContent2}>
                <h2 className={styles.titleLevel2}>Compare DVC Resale to Direct</h2>
                <p>Compare Disney&apos;s advertised price to the average resale price. Answering the question; is resale right for you.</p>
                <ResaleVsDirect resaleVsDirect={resaleVsDirect} />
            </SlideAppear>
        </div>
    )
}
