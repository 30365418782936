import { useMemo } from "react"
//import { getBlogDate } from "../../utils"
import Link from "next/link"
import styles from "@css/modules/Blogs.module.scss"
import Image from "next/image"
import cn from "classnames"
import { SlideAppear } from "@components/Animation/SlideAppear"

export function BlogItem({ title, /*publishedAt,*/ slug, blog_tags, short_description, preview_img, mobile, index }) {
    const href = `/blog/${slug}`
    //const currentDate = useMemo(() => getBlogDate(publishedAt), [publishedAt])
    const img = preview_img && (
        <Link href={href} className={styles.blogItemImgLink}>
            <Image
                className={styles.blogItemImg}
                loading="lazy"
                src={preview_img.url}
                alt={preview_img.alternativeText || title}
                width={preview_img.width}
                height={preview_img.height}
            />
        </Link>
    )
    const tags = useMemo(() => {
        if (!blog_tags) {
            return []
        }
        let t = [].concat(blog_tags)
        if (t.length > 4) {
            t = t.slice(0, 3)
            t.push({
                id: -1,
                small: true,
                name: "+ " + (blog_tags.length - t.length)
            })
        }
        return t
    }, [blog_tags])
    const restContent = (
        <>
            {/* <span className={styles.blogItemDate}>{currentDate}</span>*/}
            <Link href={href}>
                <h3 className={styles.blogItemTitle}>{title}</h3>
            </Link>
            <div className={styles.blogItemDesc}>{short_description}</div>
        </>
    )
    const content = mobile ? <div className={styles.restMobile}>{restContent}</div> : restContent
    return (
        <SlideAppear className={styles.blogItem} delay={index}>
            {img}
            {content}
            {!mobile && tags.length > 0 && (
                <div className={styles.tags}>
                    {tags.map((i) => {
                        return (
                            <span className={cn(styles.tag, i.small && styles.smallTag)} key={i.id}>
                                {i.name}
                            </span>
                        )
                    })}
                </div>
            )}
        </SlideAppear>
    )
}
