import React from "react"
import PropTypes from "prop-types"
import "intersection-observer"
import { isBrowser } from "../../utils/const"

export class Intersection extends React.PureComponent {
    static propTypes = {
        once: PropTypes.bool,
        threshold: PropTypes.number,
        onChange: PropTypes.func,
        className: PropTypes.string,
        render: PropTypes.func,
        children: PropTypes.any
    }
    /**
     * @type { IntersectionObserver }
     * @private
     */
    _observer
    /**
     * @type {function}
     * @param entry {IntersectionObserverEntry}
     * @private
     */
    _update = (entry /*, observer*/) => {
        if (entry.isIntersecting !== this.state.isIntersecting) {
            this.setState(
                {
                    isIntersecting: entry.isIntersecting
                },
                () => {
                    if (typeof this.props.onChange === "function") {
                        this.props.onChange(this.state.isIntersecting)
                    }
                }
            )
            if (this.props.once && entry.isIntersecting) {
                this._observer.disconnect()
            }
        }
    }
    state = {
        isIntersecting: false
    }

    constructor(props) {
        super(props)
        if (isBrowser) {
            this._observer = new IntersectionObserver(this._onIntersect, {
                rootMargin: "0px 0px",
                threshold: props.threshold || 0.15
            })
        }
        this.ref = React.createRef()
    }

    componentDidMount() {
        this._observer.observe(this.ref.current)
    }

    componentWillUnmount() {
        this.destroy()
    }

    /**
     * @param entries {IntersectionObserverEntry[]}
     * @param observer {IntersectionObserver}
     * @private
     */
    _onIntersect = (entries, observer) => {
        entries.forEach((entry) => {
            this._update(entry, observer)
        })
    }

    destroy = () => {
        this._observer.disconnect()
    }

    render() {
        const Render = this.props.render
        const child = Render ? <Render active={this.state.isIntersecting} /> : this.props.children
        return (
            <div className={this.props.className} ref={this.ref} data-active={this.state.isIntersecting || undefined}>
                {child}
            </div>
        )
    }
}
