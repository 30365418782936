import { ExpandWhereProps } from "@utils/search"

type Sort =
    | {}
    | {
          value: string
          column: string
          order: "ascend" | "descend"
      }
class FiltersState {
    _filters: Record<string, ExpandWhereProps> = {}
    _sort: Record<string, Sort> = {}

    //get filters() {
    //    const f = this._filters[getLocation()]
    //    if (!f) {
    //        this._filters[getLocation()] = {}
    //    }
    //    return this._filters[getLocation()]
    //}
    //set filters(data) {
    //    this._filters[getLocation()] = data
    //}

    setSort(data: Sort, url: string) {
        this._sort[url] = data
    }

    //set sort(data) {
    //    this._sort[getLocation()] = data
    //}
    getSort(url: string) {
        return this._sort[url]
    }

    //get sort() {
    //    return this._sort[getLocation()]
    //}

    setFilter = (data: ExpandWhereProps, url: string, savedSearch?: string) => {
        this._filters[url] = {
            ...(this._filters[url] || {}),
            ...data
        }
        this.savedSearch = savedSearch || null
    }

    getFilter = (url: string): ExpandWhereProps => {
        const f = this._filters[url]
        if (!f) {
            this._filters[url] = {}
        }
        return this._filters[url]
    }

    clear = (url?: string) => {
        if (url) {
            this._filters[url] = {}
        } else {
            this._filters = {}
        }
    }
    savedSearch = null
}

export const filtersState = new FiltersState()
