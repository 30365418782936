import { ReactNode, useEffect, useState } from "react"
import { TweenLite } from "gsap"
import { addCurrency } from "../../utils"

type NumberAnimationProps<T extends boolean = false> = {
    value: number
    def?: number
    currency?: T
    ease?: string
    dur?: number
    children?: (rest: number | string) => ReactNode
    start?: boolean
}

export function NumberAnimation({ value, def = 0, currency = false, start = true, ease = "power1.out", dur = 2, children }: NumberAnimationProps) {
    const [renderValue, setRenderValue] = useState(def)
    useEffect(() => {
        if (start !== false) {
            const target = { value: renderValue }
            TweenLite.to(target, dur, {
                value,
                ease,
                onUpdate: () => {
                    const newValue = Math.round(target.value)
                    if (newValue !== renderValue) {
                        setRenderValue(newValue)
                    }
                }
            })
        }
    }, [value, start])
    const rest = currency ? addCurrency(renderValue) : renderValue
    if (typeof children === "function") {
        return children(rest)
    }
    return rest
}
