import NextImage, { ImageProps } from "next/image"
import Omit from "lodash/omit"

type Props = {
    alternativeText: string
    url: string
    src?: string
    alt?: string
} & Omit<ImageProps, "src" | "alt" | "id">

export function Image({ alternativeText, url, ...rest }: Props) {
    return (
        <NextImage src={url} alt={alternativeText} {...Omit(rest, ["updatedAt", "createdAt", "previewUrl", "id", "formats", "provider", "hash", "name", "mime", "size", "ext"])} />
    )
}
