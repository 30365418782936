"use client"
import React, { useState } from "react"
import styles from "@css/modules/HomePage.module.scss"
import { Carousel } from "../../Animation/Carousel"
import Link from "next/link"
import { Image } from "../../Image"
import { Media } from "../../Media"
import { mobileSwitch } from "../utils"
import { Stars } from "../../Animation/Stars"
import { SlideAppear } from "../../Animation/SlideAppear"

function getButtons() {
    return {
        nextBtn: (go) => (
            <button className={styles.navBtn} onClick={go}>
                Next
            </button>
        ),
        prevBtn: (go) => (
            <button className={styles.navBtn} onClick={go}>
                Prev
            </button>
        )
    }
}

export function Block3({ featureResorts }) {
    const [activeSlide, setActiveSlide] = useState(0)
    if (!featureResorts.length) return null
    const render = (mobile) => {
        const buttons = !mobile && getButtons()
        const slide = featureResorts[activeSlide]
        return (
            <>
                <Carousel
                    startAt={0}
                    autoplayTime={5000}
                    classNames={{
                        itemActive: styles.itemActive,
                        item: styles.item,
                        itemNext: styles.itemNext,
                        itemPrev: styles.itemPrev,
                        root: styles.carouselRoot,
                        dots: mobile ? { item: styles.dotItem, active: styles.dotItemActive, root: styles.dotsRoot } : undefined
                    }}
                    {...buttons}
                    onChange={setActiveSlide}
                    items={featureResorts.map((i, key) => (
                        <div key={key} className={styles.slideContent}>
                            <Image {...i.image} className={styles.img} alt={i.alternativeText || i.title} quality={100} />
                            {!mobile && (
                                <Link href={"/resorts/" + i.resort.slug}>
                                    <div className={styles.slideContentText}>
                                        <h3>{i.title}</h3>
                                        <p>{i.description}</p>
                                    </div>
                                </Link>
                            )}
                        </div>
                    ))}
                />
                {mobile ? (
                    <Link href={"/resorts/" + slide.resort.slug}>
                        <div className={styles.slideContentText}>
                            <h3>{slide.title}</h3>
                            <p>{slide.description}</p>
                        </div>
                    </Link>
                ) : (
                    <Stars className={styles.stars} intersectionClass={styles.starsIntersection} starsCount={15} />
                )}
            </>
        )
    }
    return (
        <SlideAppear className={styles.block3container}>
            <h2 className={styles.titleLevel2}>Featured Resorts</h2>
            <Media render={render} query={`(max-width: ${mobileSwitch})`} />
            <div className={styles.overBtn}>
                <Link href="/resorts" className={styles.btnTransparent}>
                    View All Resorts
                </Link>
            </div>
        </SlideAppear>
    )
}
