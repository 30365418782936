import { mobileSwitch } from "@components/HomePage/utils"
import React, { lazy, ReactNode } from "react"
import { Stars } from "@components/Animation/Stars"
import css from "@css/modules/HomePage.module.scss"
import { Media } from "@components/Media"
import Image from "next/image"
import b1L2 from "@public/images/homePage/block1/layer2.png"
import b1L1 from "@public/images/homePage/block1/layer1.png"

const Player = lazy(() => import("@components/Animation/LottiePlayer"))

const ZoomContent = ({ mobile }) => {
    // const zoom = Math.max(useZoom(), 1)
    return (
        <div className={css.block1LayerWrapper}>
            <Player src={"/Fireworks.json"} className={css.player} loop autoplay style={{ width: 375 }} />
            <div className={css.block1Layer4} />
            <div className={css.block1Layer3} />
            {!mobile && (
                <>
                    <Image loading="eager" src={b1L2} alt="block 1 layer 5" className={css.block1Layer2} />
                    <Image loading="eager" src={b1L1} alt="block 1 layer 6" className={css.block1Layer1} />
                </>
            )}
        </div>
    )
}

export function Block1DynamicContent() {
    return (
        <Media
            query={`(max-width: ${mobileSwitch}`}
            render={(mobile) => {
                let top: ReactNode
                if (mobile) {
                    top = <Stars className={css.stars} intersectionClass={css.starsIntersection} starsCount={20} />
                } else {
                    top = <div className={css.block1Layer6} />
                }
                return (
                    <>
                        {top}
                        <ZoomContent mobile={mobile} />
                    </>
                )
            }}
        />
    )
}
