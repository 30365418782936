"use client"
import { Intersection } from "../Intersection/Intersection"
import { ReactNode, useState } from "react"
import css from "@css/Components.module.scss"
import cn from "classnames"

type Props = {
    className?: string
    children: ReactNode
    delay?: number
}
export function SlideAppear({ className, children, delay = 0 }: Props) {
    const [shown, setShown] = useState(false)
    return (
        <Intersection once className={cn(className, shown && css.active, css.appear, delay && css["delay" + delay])} onChange={setShown}>
            {children}
        </Intersection>
    )
}
