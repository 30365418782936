import { memo, useEffect, useRef } from "react"
import { Intersection } from "../Intersection/Intersection"

function createDots(dotCount, ctx, c) {
    const dotArray = []
    for (let i = 0; i < dotCount; i++) {
        let radius, x, y
        radius = Math.max(1, Math.random() * 4)
        x = Math.random() * (c.width - radius * 2) + radius
        y = Math.random() * (c.height - radius * 2) + radius
        const xVelocity = (Math.random() * radius - 1) / 18
        const yVelocity = (Math.random() * radius - 1) / 18
        const fillColor = "white"
        const shadowBlur = 10
        const shadowColor = "white"
        const globalAlpha = (Math.random() * 5 + 5) / 10
        dotArray.push(new Dot(radius, x, y, xVelocity, yVelocity, fillColor, shadowBlur, shadowColor, globalAlpha, ctx, c))
    }
    return dotArray
}

function Dot(r, x, y, xV, yV, fC, sB, sC, gA, ctx, c) {
    this.radius = r
    this.x = x
    this.y = y
    this.xVelocity = xV
    this.yVelocity = yV
    this.fillColor = fC
    this.shadowBlur = sB
    this.shadowColor = sC
    this.globalAlpha = gA

    this.updateDots = function () {
        if (x + this.radius > c.width || x - this.radius < 0) this.xVelocity = -this.xVelocity
        if (y + this.radius > c.height || y - this.radius < 0) this.yVelocity = -this.yVelocity
        x += this.xVelocity
        y += this.yVelocity

        this.drawDots()
    }

    this.drawDots = function () {
        ctx.beginPath()
        ctx.arc(x, y, this.radius, 0, Math.PI * 2, false)
        ctx.shadowBlur = this.shadowBlur
        ctx.shadowColor = this.shadowColor
        ctx.globalAlpha = this.globalAlpha
        ctx.fillStyle = this.fillColor
        ctx.fill()
    }
}

function draw(dots, ctx, c) {
    ctx.clearRect(0, 0, c.width, c.height)

    for (let i = 0; i < dots.length; i++) {
        dots[i].updateDots()
    }
}

function animate(dots, ctx, c, frame) {
    frame.id = requestAnimationFrame(() => {
        draw.apply(null, arguments)
        animate.apply(null, arguments)
    })
}

function setValues(ctx, c, starsCount) {
    const dotCount = starsCount || Math.floor(((c.width / 2) * (c.height / 2)) / 1000)
    return createDots(dotCount, ctx, c)
}

function StarsComponent({ className, intersectionClass, starsCount }) {
    /**
     * @type {React.MutableRefObject<HTMLCanvasElement>}
     */
    const ref = useRef()
    /**
     * @type {React.MutableRefObject<{start, stop, active}>}
     */
    const controller = useRef({
        start: null,
        stop: null
    })
    useEffect(() => {
        const c = ref.current
        c.width = c.offsetWidth
        c.height = c.offsetHeight
        const ctx = c.getContext("2d")
        const frame = {}
        let dots = setValues(ctx, c, starsCount)
        draw(dots, ctx, c)
        const start = () => {
            animate(dots, ctx, c, frame)
        }
        const stop = () => {
            cancelAnimationFrame(frame.id)
        }
        Object.assign(controller.current, { start, stop })
        let width = window.innerWidth
        let height = window.innerHeight
        const listener = () => {
            if (Math.abs(width - window.innerWidth) > 50 || Math.abs(height - window.innerHeight) > 50) {
                width = window.innerWidth
                controller.current.stop()
                c.width = c.offsetWidth
                c.height = c.offsetHeight
                dots = setValues(ctx, c, starsCount)
                draw(dots, ctx, c)
                if (controller.current.active) {
                    animate(dots, ctx, c, frame)
                }
            }
        }
        window.addEventListener("resize", listener)
        return () => {
            window.removeEventListener("resize", listener)
            stop()
        }
    }, [])
    const canvas = <canvas ref={ref} className={className} />
    const Render = ({ active }) => {
        useEffect(() => {
            controller.current.active = active
            if (!controller.current.start) return
            if (active) {
                controller.current.start()
            } else {
                controller.current.stop()
            }
        }, [active])
        return canvas
    }
    return <Intersection render={Render} threshold={0.15} className={intersectionClass} />
}

export const Stars = memo(StarsComponent)
