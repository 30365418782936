"use client"
import styles from "@css/modules/HomePage.module.scss"
import { MailIcon } from "@css/icons/SVG"
import { LongArrowDown } from "@css/icons/LongArrowDown"
import { Media } from "../../Media"
import { mobileSwitch } from "../utils"
import { notification } from "antd"
import { useState } from "react"
import { SlideAppear } from "../../Animation/SlideAppear"

export function Subscribe() {
    const [sent, setSent] = useState(false)
    const [loading, setLoading] = useState(false)
    const onSubmit = (event) => {
        event.preventDefault()
        setLoading(true)
        const formData = new FormData()
        formData.append("email", event.target.elements.email.value)
        fetch("https://getform.io/f/9fbf2034-044f-4ad6-a1ac-44107053956b", {
            method: "POST",
            body: formData
        })
            .then(() => {
                setSent(true)
            })
            .catch((error) => {
                console.log(error)
                setLoading(false)
                notification.warning({
                    message: "Submitting error occurred"
                })
            })
    }
    return (
        <SlideAppear className={styles.subscribeSection}>
            {sent ? (
                <div className={styles.thank}>Thank you for subscription!</div>
            ) : (
                <>
                    <h3>Sign up for weekly updates</h3>
                    <h4>Don’t miss our new offers and keep up to date.</h4>
                    <form className={styles.form} onSubmit={onSubmit}>
                        <label>
                            <MailIcon />
                            <input type="email" placeholder="example@gmail.com" name="email" required />
                        </label>
                        <button disabled={loading} type="submit" className={styles.btnFilled} aria-label="Sign me Up">
                            <Media query={`(max-width: ${mobileSwitch})`} notMatchChildren={"Sign me Up"}>
                                <LongArrowDown />
                            </Media>
                        </button>
                    </form>
                </>
            )}
        </SlideAppear>
    )
}
